const PREFIX = 'MY_SHIPPING_INFO';

export const SHIPPING_INFO_RECEIVED = `${PREFIX}/SHIPPING_INFO_RECEIVED`;
export const shippingInfoReceived = ({ shippingInfo }) => ({
    type: SHIPPING_INFO_RECEIVED,
    payload: shippingInfo,
});

export const SEARCH_QUERY = `${PREFIX}/SEARCH_QUERY`;
export const searchShippingQuery = ({ query }) => ({
    type: SEARCH_QUERY,
    payload: query,
});

export const CHANGE_PAGE_SIZE = `${PREFIX}/CHANGE_PAGE_SIZE`;
export const changePageSize = ({ size }) => ({
    type: CHANGE_PAGE_SIZE,
    payload: size,
});

export const UPDATE_SHIPPINGINFO = `${PREFIX}/UPDATE_SHIPPINGINFO`;
export const updateShippingInfo = ({ shippingInfo }) => ({
    type: UPDATE_SHIPPINGINFO,
    payload: shippingInfo,
});

export const SHIPPINGINFO_UPDATED = `${PREFIX}/SHIPPINGINFO_UPDATED`;
export const onShippingInfoUpdate = ({ shippingInfos }) => ({
    type: SHIPPINGINFO_UPDATED,
    payload: shippingInfos,
});