import { createReducer } from 'utils/redux';
import {
    SHIPPING_INFO_RECEIVED,
    SEARCH_QUERY,
    CHANGE_PAGE_SIZE,
    SHIPPINGINFO_UPDATED,
} from './actions';

const initialState = {
    shippingInfo: null,
    query: '',
    size: 10,
    lastUpdated: null,
};

export default createReducer(initialState, {
    [SHIPPING_INFO_RECEIVED]: onShippingInfoReceived,
    [SEARCH_QUERY]: onSearchQuery,
    [CHANGE_PAGE_SIZE]: onPageSize,
    [SHIPPINGINFO_UPDATED]: onShippingInfoUpdated,
});

function onShippingInfoReceived(state, action) {
    return {
        ...state,
        shippingInfo: action.payload,
    };
}

function onSearchQuery(state, action) {
    return {
        ...state,
        query: action.payload,
    };
}

function onPageSize(state, action) {
    return {
        ...state,
        size: action.payload,
    };
}

function onShippingInfoUpdated(state, action) {
    const updatedShippingInfo = action.payload;
    const changedShippingInfo = state.shippingInfo.map(info => {
        const updatedInfo = updatedShippingInfo.find(i => i.id === info.id);
        if (updatedInfo)
            return { ...info, enabled: updatedInfo.enable };

        return info;
    });

    return {
        ...state,
        shippingInfo: changedShippingInfo,
        lastUpdated: new Date(),
    };
}