exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Scanner_scanner-container{position:absolute;background:none;border:none;top:0;right:4.25em;height:100%;cursor:pointer}.Scanner_scanner-container.Scanner_active svg{color:#c00}.Scanner_scanner-container svg{max-width:100%;max-height:60%}", ""]);

// exports
exports.locals = {
	"scanner-container": "Scanner_scanner-container",
	"scannerContainer": "Scanner_scanner-container",
	"active": "Scanner_active"
};