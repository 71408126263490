import styles from '../AccountMenu.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink, Link, CoreReportLink } from 'components/primitives/links';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import LogoutButton from './LogoutButton';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { stopRepresentingCustomer } from 'behavior/user';
import { routesBuilder } from 'routes';
import ARStatementReportLink from 'components/primitives/links/ARStatementReportLink';

const ImpersonatedCustomerMenuItems = ({ logoutDelay }) => {
  const dispatch = useDispatch();
  const { name, customerName } = useSelector(({ user }) => ({
    name: user.name,
    customerName: user.customer?.name,
  }), shallowEqual);
  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <div className={styles.bodyHeader}>
        <div>
          <SimpleText textKey="Representing" /> <span className="customer-name"><b>{customerName}</b></span>
        </div>
        <div>
          <button className={`${linkStyles.link} ${linkStyles.arrowed} text-left`} onClick={() => dispatch(stopRepresentingCustomer())}>
            <SimpleText textKey="StopRepresentation" />
          </button>
        </div>
      </div>
      <div className={styles.bodyHeader}>
        <span className="user-name">
          <SimpleText textKey="LoggedInAs" /> <b>{name}</b>
        </span>
      </div>
      <Link to={routesBuilder.forRepresent} options={{ backTo }} className={styles.item}>
        <SimpleText textKey="RepresentAnotherCustomer" />
      </Link>
      <MyAccountLink className={styles.item} />
      <ARStatementReportLink className={styles.item} />
      <CoreReportLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

ImpersonatedCustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default ImpersonatedCustomerMenuItems;
