import { PARTS_FIND_REQUEST_RESULT, PARTS_FIND_REQUEST_SUBMITTED, PARTS_FIND_REQUEST_RECEIVED, PartsFindRequestAction } from './actions';

type State = {
  successStatus: boolean | null;
}

const initialState: State = {
  successStatus: null,
};

export default function reducer(state: State = initialState, action: PartsFindRequestAction) {
  switch (action.type) {
    case PARTS_FIND_REQUEST_RECEIVED:
      return { ...state, questions: action.payload };
    case PARTS_FIND_REQUEST_RESULT:
      return { ...state, successStatus: action.payload };
    case PARTS_FIND_REQUEST_SUBMITTED:
      return { ...state, successStatus: null };
    default:
      return state;
  }
}
