import documentEpic from './epic.document';
import reorderEpic from './epic.reorder';
import createOrderEpic from './epic.createOrder';
import quoteEpic from './epic.quote';
import editEpic from './epic.edit';
import orderAuthorizationEpic from './epic.authorization';

import { combineEpics } from 'redux-observable';

export default combineEpics(documentEpic, reorderEpic, createOrderEpic, quoteEpic, editEpic, orderAuthorizationEpic);
