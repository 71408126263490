import styles from './Scanner.module.scss';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { SimpleText } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';
import { Modal, useModal } from 'components/objects/modals';
import { ScannerIcon } from 'components/primitives/icons';
import { searchProducts, searchProductsClear } from 'behavior/products/search/suggestions';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { useRoutes } from 'components/primitives/route';
import { defaultSearchParams } from './../search/constants';
import { toasts } from 'behavior/toasts';
import { connect } from 'react-redux';

const searchRoute = { routeName: RouteName.Search, params: { q: defaultSearchParams } };

const Scanner = ({ children, autosuggestRef, valueAutosuggest, setValueAutosuggest, suggestions, viewMode, searchProducts, navigateTo }) => {
  const { opened, show, hide } = useModal();
  const [scannerValue, setScannerValue] = useState();
  const [isScanning, setIsScanning] = useState(false);
  const isMobile = useIsMobileViewport();
  const [routePath] = useRoutes([searchRoute]);

  const onScannerClick = useCallback(event => {
    event.preventDefault();

    isScanning ? toggleScanning(false) : toggleScanning(true);
  }, [isScanning]);

  const toggleScanning = useCallback(scan => {
    setIsScanning(scan);

    if (scan)
      isMobile ? show() : autosuggestRef.current.focus();
    else {
      searchProductsClear();
      isMobile ? hide() : autosuggestRef.current.blur();
    }
  }, []);

  const onError = useCallback(() => {
    toggleScanning(false);
    toasts.info(<SimpleText textKey="CameraIsDisabled" />);
  }, []);

  useEffect(() => {
    if (!isScanning)
      return;

    if (suggestions && suggestions.length === 1) {
      setValueAutosuggest('');
      navigateTo(suggestions[0].routeData, suggestions[0].url);
    }
    else {
      const encodedValue = encodeURIComponent(isMobile ? scannerValue : valueAutosuggest);
      const searchURL = routePath.replace(defaultSearchParams, encodedValue);
      navigateTo({ routeName: RouteName.Search, params: { q: isMobile ? scannerValue : valueAutosuggest, viewMode } }, searchURL);
    }
    toggleScanning(false);
  }, [suggestions]);

  return (
    <>
      {children}

      <Modal opened={opened} hide={() => toggleScanning(false)} className={'modal-content'}>
        {opened && (
          <BarcodeScannerComponent
            onUpdate={(err, result) => {
              if (result) {
                searchProducts(result.text);
                setScannerValue(result.text);
              }
            }}
            onError={error => onError(error)}
            stopStream={!opened}
          />
        )}
      </Modal>

      <button className={`${styles.scannerContainer} ${isScanning ? styles.active : ''}`} onClick={onScannerClick}>
        <ScannerIcon />
      </button>
    </>
  );
};

Scanner.propTypes = {
  children: PropTypes.node,
  autosuggestRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  valueAutosuggest: PropTypes.string,
  setValueAutosuggest: PropTypes.func,
  suggestions: PropTypes.array,
  viewMode: PropTypes.string,
  searchProducts: PropTypes.func,
};

const mapStateToProps = ({
  suggestions,
  settings: { search },
}) => ({
  suggestions: suggestions.products,
  viewMode: search && search.defaultViewMode,
});

export default connect(
  mapStateToProps,
  { searchProducts, searchProductsClear, navigateTo },
)(Scanner);