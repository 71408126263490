import { loadMyShippingInfoPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';
import { of } from 'rxjs';
import { shippingInfoReceived } from './actions';

export default (routeData, state$, { api }) => {
    return api.graphApi(loadMyShippingInfoPageQuery).pipe(
        map(({ pages, viewer: { customer: { shippingAddresses } } }) => {
            const page = pages.shippingInfo;
            if (!page)
                return null;

            page.component = PageComponentNames.ShippingInfo;

            page.backTo = getBackTo(state$, [
                RouteName.ShippingInfo,
            ], routeData.params && routeData.params.language);

            return { page, action$: of(shippingInfoReceived({ shippingInfo: shippingAddresses })) };
        }),
        initSystemPageContent(),
    );
};
