import { DOCUMENTS_REQUESTED, documentsReceived } from '../actions';
import { DocumentType } from 'behavior/documents';
import {
  ordersQuery,
  quotesQuery,
  invoicesQuery,
  returnOrdersQuery,
  creditNotesQuery,
  returnReceiptsQuery,
  shipmentsQuery,
  backordersQuery,
} from '../queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

export default function (action$, _, { api, logger }) {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  return action$.pipe(
    ofType(DOCUMENTS_REQUESTED),
    switchMap(({ payload: { options, documentType } }) => {
      const query = getDocumentQuery(documentType);
      if (!query) {
        logger.error(`Query for document of '${documentType}' type is not registered.`);
        return EMPTY;
      }

      return api.graphApi(query, { options }).pipe(
        pluck('documents', 'docs'),
        mergeMap(documents => of(
          documentsReceived(documents && documents.list, options.page.index),
          unsetLoading,
        )),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        takeUntil(locationChanged$),
        startWith(setLoading),
      );
    }),
  );
}

function getDocumentQuery(documentType) {
  switch (documentType) {
    case DocumentType.Order:
      return ordersQuery;
    case DocumentType.Backorder:
      return backordersQuery;
    case DocumentType.Quote:
      return quotesQuery;
    case DocumentType.Invoice:
      return invoicesQuery;
    case DocumentType.ReturnOrder:
      return returnOrdersQuery;
    case DocumentType.CreditNote:
      return creditNotesQuery;
    case DocumentType.ReturnReceipt:
      return returnReceiptsQuery;
    case DocumentType.Shipment:
      return shipmentsQuery;
    default:
      return;
  }
}
