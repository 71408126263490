import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap, startWith } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import {
  checkoutInfoUpdated,
  CHECKOUT_PROMOTION_CODE_ADD,
} from './actions';
import { navigateOnIncorrect } from './helpers';
import { getAddCouponMutation } from './queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    return action$.pipe(
      ofType(CHECKOUT_PROMOTION_CODE_ADD),
      switchMap(action => waitForSubmit(() => api.graphApi(getAddCouponMutation(!!state$.value.page.info?.quote), {
        code: action.payload,
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      },
      ).pipe(
        mergeMap(({ checkout }) => {
          let actionResult = navigateOnIncorrect(state$.value.page.info);
          if (checkout) {
            const selectResult = checkout.promotionCode.addCoupon;
            if (selectResult.info.valid)
              actionResult = checkoutInfoUpdated(selectResult.info);
          }

          return of(actionResult, unsetLoadingIndicator());
        }),
        retryWithToast(action$, logger),
        startWith(setLoadingIndicator()),
      ))));
  };
}
