import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link from './base/Link';
import { useSelector } from 'react-redux';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';

const ShippingInfoLink = ({ abilities: [manageShippingAddresses], ...attributes }) => {
    const isAuthenticated = useSelector(s => s.user.isAuthenticated);
    if (!isAuthenticated)
        return null;

    if (manageShippingAddresses !== AbilityState.Available)
        return null;

    return (
        <Link {...attributes} to={routesBuilder.forMyShippingInfo}>
            <SimpleText textKey="MyShippingInfo" />
        </Link>
    );
};

ShippingInfoLink.propTypes = {
    abilities: abilitiesPropType.isRequired,
};

export default memo(withAbilities(ShippingInfoLink, [AbilityTo.ManageShippingAddresses]));
