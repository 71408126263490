type PartsFindRequestInput = {
    answers: string[];
}
type QuestionType = {
    text: string;
    isRequired: boolean;
}

export const PARTS_FIND_REQUEST_REQUESTED = 'PARTS_FIND_REQUEST_REQUESTED' as const;
export const sendPartsFindRequest = () => ({ 
    type: PARTS_FIND_REQUEST_REQUESTED,  
});

export const PARTS_FIND_REQUEST_RECEIVED = 'PARTS_FIND_REQUEST_RECEIVED' as const;
export const sendPartsFindReceived = (questions: QuestionType[]) => ({
    type: PARTS_FIND_REQUEST_RECEIVED,
    payload: questions,
});
  
export const PARTS_FIND_REQUEST_SUBMITTED = 'PARTS_FIND_REQUEST_SUBMITTED' as const;
export const sendPartsFindSubmit = (data: PartsFindRequestInput) => ({ 
    type: PARTS_FIND_REQUEST_SUBMITTED, 
    payload: data, 
});

export const PARTS_FIND_REQUEST_RESULT = 'PARTS_FIND_REQUEST_RESULT' as const;
export const sendPartsFindSubmitted = (success: boolean | null) => ({
    type: PARTS_FIND_REQUEST_RESULT,
    payload: success,
});

export type PartsFindRequestAction = ReturnType<
| typeof sendPartsFindRequest
| typeof sendPartsFindReceived
| typeof sendPartsFindSubmit
| typeof sendPartsFindSubmitted
>