export const WISH_LIST_ADD_PRODUCT = 'WISH_LIST_ADD_PRODUCT';
export const addProductToWishList = (productId, uomId, variantId, warehouseId) => ({
  type: WISH_LIST_ADD_PRODUCT,
  payload: { productId, uomId, variantId, warehouseId },
});

export const WISH_LIST_ADD_BASKET = 'WISH_LIST_ADD_BASKET';
export const addBasketToWishList = () => ({
  type: WISH_LIST_ADD_BASKET,
});

export const WISH_LIST_UPDATED = 'WISH_LIST_UPDATED';
export const updateWishList = modifiedDate => ({
  type: WISH_LIST_UPDATED,
  payload: { modifiedDate },
});

export const WISH_LIST_REMOVE_PRODUCT = 'WISH_LIST_REMOVE_PRODUCT';
export const removeProductFromWishList = (productId, uomId, variantId, warehouseId) => ({
  type: WISH_LIST_REMOVE_PRODUCT,
  payload: { productId, uomId, variantId, warehouseId },
});

export const WISH_LIST_PRODUCT_REMOVED = 'WISH_LIST_PRODUCT_REMOVED';
export const productRemovedFromWishList = ({ productId, uomId, variantId, warehouseId }) => ({
  type: WISH_LIST_PRODUCT_REMOVED,
  payload: { productId, uomId, variantId, warehouseId },
});

export const WISH_LIST_MOVE_PRODUCT_TO_BASKET = 'WISH_LIST_MOVE_PRODUCT_TO_BASKET';
export const moveProductToBasket = (productId, uomId, variantId, warehouseId) => ({
  type: WISH_LIST_MOVE_PRODUCT_TO_BASKET,
  payload: { productId, uomId, variantId, warehouseId },
});