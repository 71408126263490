import { merge, from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, startWith, exhaustMap, pluck } from 'rxjs/operators';
import { DOCUMENT_CREATE_ORDER_REQUESTED, createOrderReceived } from '../actions';
import { createOrderMutation } from '../queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { CreateOrderResult } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { basketChangeCompleted, navigateTo } from 'behavior/events';
import {
  trackAddToBasket,
  trackRemoveFromBasket,
  getProductsTrackingDataFromLines,
} from 'behavior/analytics';
import { reloadLocation } from 'behavior/routing';

export default (action$, state$, { api, logger }) =>
  action$.pipe(
    ofType(DOCUMENT_CREATE_ORDER_REQUESTED),
    exhaustMap(({ payload }) =>
      api.graphApi(createOrderMutation,
        {
          input: payload,
          requestModifiedLines: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
        },
        { retries: 0 },
      ).pipe(
        pluck('documents', 'createOrder'),
        mergeMap(createOrderResult => {
          if (createOrderResult == null)
            return of(reloadLocation());

          const { result, basket, deletedLines } = createOrderResult;
          const linesUpdated = result === CreateOrderResult.Success
            ? basket.productLines.totalCount
            : 0;
          const resultActions = [
            unsetLoadingIndicator(),
            createOrderReceived(result),
            basketChangeCompleted(linesUpdated),
          ];

          if (result !== CreateOrderResult.Success)
            return from(resultActions);

          const removedProducts = deletedLines ? getProductsTrackingDataFromLines(deletedLines.list) : [];
          if (removedProducts && removedProducts.length)
            resultActions.push(trackRemoveFromBasket({ products: removedProducts }));

          const addedProducts = basket.addedLines ? getProductsTrackingDataFromLines(basket.addedLines.list) : [];
          if (addedProducts && addedProducts.length) {
            resultActions.push(trackAddToBasket({ products: addedProducts }));
          }

          return merge(
            from(resultActions),
            of(navigateTo(routesBuilder.forBasket())),
          );
        }),
        retryWithToast(action$, logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
