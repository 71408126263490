import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDownload } from 'utils/hooks';
import { Subject, timer } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';
import { toasts } from 'behavior/toasts';

const DownloadFileLink = ({ downloadRef, children, errorMessageKey }) => {
  const download = useDownload();
  const dispatch = useDispatch();
  const [loadState, setLoadState] = useState();

  const loadingStateSubjRef = useRef();
  if (!loadingStateSubjRef.current)
    loadingStateSubjRef.current = new Subject();

  useEffect(() => {
    let prev;
    const sub = loadingStateSubjRef.current.pipe(
      switchMap(v => timer(v === DownloadStates.Waiting || !prev || prev !== DownloadStates.Waiting ? 450 : 2000).pipe(
        mapTo(v),
        tap(v => prev = v),
      )),
    ).subscribe(setLoadState);

    return () => sub.unsubscribe();
  }, [setLoadState]);

  useEffect(() => {
    if (loadState === DownloadStates.Failure) {
      toasts.error(<RichText textKey={errorMessageKey || 'ProblemGettingYourFile'} disableInsiteEditor />);
    }
  }, [loadState]);
  const handleClick = e => {
    e.preventDefault();
    loadingStateSubjRef.current.next(DownloadStates.Waiting);
    dispatch(setLoadingIndicator());

    const onFinish = state => {
      loadingStateSubjRef.current.next(state);
      dispatch(unsetLoadingIndicator());
    };

    download(downloadRef,
      () => void onFinish(DownloadStates.Success),
      () => void onFinish(DownloadStates.Failure),
    );
  };

  return (
    <>
      <a
        href={downloadRef}
        className={`${btnStyles.btn} ${btnStyles.btnBig}`}
        rel="noopener noreferrer nofollow"
        onClick={handleClick}
        aria-describedby={loadState && loadState !== DownloadStates.Success ? downloadMessageId : null}
      >{children}</a>
    </>
  );
};

DownloadFileLink.propTypes = {
  downloadRef: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  errorMessageKey: PropTypes.string,
};

export default DownloadFileLink;

const DownloadStates = {
  Success: 0,
  Waiting: 1,
  Failure: 2,
};

const downloadMessageId = 'dwnld-msg';