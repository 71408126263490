import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link from './base/Link';
import { useSelector } from 'react-redux';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';

const CoreReportLink = ({ abilities: [downloadCoreReport], ...attributes }) => {
    const isAuthenticated = useSelector(s => s.user.isAuthenticated);
    if (!isAuthenticated)
        return null;

    if (downloadCoreReport !== AbilityState.Available)
        return null;

    return (
        <Link {...attributes} to={routesBuilder.forCoreReport}>
            <SimpleText textKey="MyCoreReport" />
        </Link>
    );
};

CoreReportLink.propTypes = {
    abilities: abilitiesPropType.isRequired,
};

export default memo(withAbilities(CoreReportLink, [AbilityTo.DownloadCoreReport]));
