import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, mergeMap, takeUntil, map } from 'rxjs/operators';
import { PARTS_FIND_REQUEST_SUBMITTED, PARTS_FIND_REQUEST_REQUESTED, sendPartsFindSubmitted, PartsFindRequestAction, sendPartsFindReceived } from './actions';
import { partsFindRequestMutation, partsFindRequestQuery } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { LOCATION_CHANGED } from 'behavior/events';

type PartsFindMutationResponse = {
  partsFindRequestFeedback: boolean | null;
}

const partsFindRequestEpic: Epic<PartsFindRequestAction> = (action$, _, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const partsFindRequestLoad$ = action$.pipe(
    ofType(PARTS_FIND_REQUEST_REQUESTED),
    switchMap(() => api.graphApi(partsFindRequestQuery)
      .pipe(
        map(data => sendPartsFindReceived(data.questions)),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      ),
    ),
  );

  const submitted$ = action$.pipe(
    ofType(PARTS_FIND_REQUEST_SUBMITTED),
    switchMap(action => api.graphApi<PartsFindMutationResponse>(partsFindRequestMutation, { data: action.payload }, { retries: 0 }).pipe(
      mergeMap(result => [sendPartsFindSubmitted(result.partsFindRequestFeedback), resetCaptcha(), unlockForm(FormLockKeys.PartsFindRequest)]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(), unlockForm(FormLockKeys.PartsFindRequest))),
      retryWithToast(action$, logger, _ => of(resetCaptcha(), unlockForm(FormLockKeys.PartsFindRequest))),
    )),
  );
  return merge(submitted$, partsFindRequestLoad$);
};

export default partsFindRequestEpic;