import { retryWithToast } from 'behavior/errorHandling';
import { LOCATION_CHANGED } from 'behavior/events';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { onShippingInfoUpdate, UPDATE_SHIPPINGINFO } from './actions';
import { updateShippingInfoMutation } from './queries';

const myShippingInfoEpic = (action$, _state$, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    return action$.pipe(
        ofType(UPDATE_SHIPPINGINFO),
        switchMap(action => api.graphApi(updateShippingInfoMutation, { input: action.payload }).pipe(
            mergeMap(() => of(
                onShippingInfoUpdate({ shippingInfos: action.payload }),
                unsetLoadingIndicator(),
            )),
            retryWithToast(action$, logger, _ => of(unsetLoadingIndicator)),
            takeUntil(locationChanged$),
            startWith(setLoadingIndicator()),
        )),
    );
};

export default myShippingInfoEpic;